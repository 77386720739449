import React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import CircularProgress from "@material-ui/core/CircularProgress"

import { makeStyles } from "@material-ui/core/styles"

import ProductCard from "./ProductCard"

const useStyles = makeStyles(theme => ({
  mainContainer: {
    paddingBottom: "1rem",
    paddingTop: "1rem",
  },
  loading: {
    textAlign: "center",
    paddingTop: "5rem",
    paddingBottom: "5rem",
  },
}))

const ProductsView = ({ catLink, categories, catLinkKey }) => {
  const classes = useStyles()

  //console.log(categories)

  return (
    <Container maxWidth="lg">
      <Box classes={{ root: classes.mainContainer }} id={"products-section"}>
        <Grid container justifyContent="space-evenly" alignItems="center">
          {categories.length > 0 ? (
            categories.map((category, index) => {
              let multiProduct = category.products.length > 1
              return (
                <ProductCard
                  key={index}
                  multiProduct={multiProduct}
                  category={category}
                  catLink={catLink}
                  catLinkKey={catLinkKey}
                ></ProductCard>
              )
            })
          ) : (
            <Box classes={{ root: classes.loading }}>
              <CircularProgress color="secondary" />
            </Box>
          )}
        </Grid>
      </Box>
    </Container>
  )
}

export default ProductsView
