import React from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Box from "@material-ui/core/Box"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import Grid from "@material-ui/core/Grid"
import { Link } from "gatsby"

import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import AddIcon from "@mui/icons-material/Add"

import ProductCardExtended from "./ProductCardExtended"

import { makeStyles, styled } from "@material-ui/core/styles"

import priceFormat from "../../helperFunc/priceFormat"

const useStyles = makeStyles(theme => ({
  boxGrids: {
    paddingBottom: "0.8rem",
    paddingTop: "0.8rem",
    paddingRight: "0.5rem",
    paddingLeft: "0.5rem",
    [theme.breakpoints.up("sm")]: {
      paddingRight: "0.8rem",
      paddingLeft: "0.85rem",
    },
  },
  mainCard: { borderRadius: "1rem" },
  titleArea: {
    backgroundColor: theme.palette.common.offWhite,

    paddingTop: "0.4rem",
    paddingBottom: "0.4rem",
  },
  titleText: {
    fontSize: "1.1rem",
  },

  priceText: {
    color: theme.palette.common.grey,

    fontSize: "0.8rem",
  },

  bottomArea: {
    backgroundColor: theme.palette.common.offWhite,
    height: "0px",
    padding: "0px",
  },
  expandMoreIconCol: {
    color: theme.palette.common.white,
  },
  expandMoreIcon: {
    padding: ".5rem",
    top: "-40px",
    right: "20px",
    backgroundColor: theme.palette.secondary.dark,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },

  addIcon: {
    padding: ".5rem",
    top: "-40px",
    right: "20px",
    marginLeft: "auto",
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.offWhite,
    },
  },

  extraContentArea: {
    backgroundColor: theme.palette.common.offWhite,
  },
  addIconCol: {
    color: theme.palette.primary.main,
  },
  unavailable: {
    zIndex: "9",
    lineHeight: "1.1rem",
    width: "100%",
    textAlign: "center",
    backgroundColor: theme.palette.common.grey,
    padding: "0.5rem",
    color: theme.palette.common.white,
    textTransform: "uppercase",
  },
  imageArea: {
    height: "100%",
    width: "100%",
  },
}))

const ExpandMore = styled(props => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}))

const ProductCard = ({ category, multiProduct, catLink, catLinkKey }) => {
  const myRef = React.useRef(null)
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  React.useEffect(() => {
    if (category.slug === catLink) {
      setExpanded(true)
      myRef.current.scrollIntoView()
    } else {
      setExpanded(false)
    }
  }, [catLink, category.slug, catLinkKey])

  const classes = useStyles()
  let products = [...category.products]
  products.sort((a, b) => (a.order > b.order ? 1 : -1))

  let imgStyle =
    !multiProduct && products[0].inStock === false
      ? { WebkitFilter: "grayscale(100%)", filter: "grayscale(100%)" }
      : {}

  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
      classes={{ root: classes.boxGrids }}
      id={`${category.slug}`}
      ref={myRef}
    >
      <Card classes={{ root: classes.mainCard }} elevation={0}>
        <CardActionArea
          onClick={multiProduct ? handleExpandClick : undefined}
          component={!multiProduct ? Link : undefined}
          to={
            !multiProduct
              ? `/${category.slug.toLowerCase()}/${encodeURIComponent(
                  products[0].name.toLowerCase().replace(/\s/g, "")
                )}`
              : undefined
          }
        >
          <CardContent classes={{ root: classes.titleArea }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography
                  variant="body2"
                  component="div"
                  classes={{ root: classes.titleText }}
                >
                  {category.name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  component="div"
                  variant="body1"
                  classes={{ root: classes.priceText }}
                >
                  {multiProduct
                    ? null
                    : `${
                        products[0].pricePrefix
                          ? `${products[0].pricePrefix} `
                          : ``
                      } ${priceFormat(products[0].price)}`}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <CardMedia
            component={Box}
            style={{
              backgroundImage: `url(${category.images[0].url})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              width: "100%",
              height: "140px",
              ...imgStyle,
            }}
          >
            {products[0].inStock === false ? (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                classes={{ root: classes.imageArea }}
              >
                <Grid item>
                  <Typography
                    variant="body1"
                    classes={{ root: classes.unavailable }}
                  >
                    {"Currently unavailable"}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </CardMedia>
        </CardActionArea>
        <CardActions disableSpacing classes={{ root: classes.bottomArea }}>
          {multiProduct ? (
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              classes={{ root: classes.expandMoreIcon }}
              color="secondary"
            >
              <ExpandMoreIcon classes={{ root: classes.expandMoreIconCol }} />
            </ExpandMore>
          ) : (
            <IconButton
              aria-label="go to product"
              classes={{
                root: classes.addIcon,
              }}
              disabled={products[0].inStock === false ? true : false}
              color="secondary"
              component={Link}
              to={`/${category.slug.toLowerCase()}/${encodeURIComponent(
                products[0].name.toLowerCase().replace(/\s/g, "")
              )}`}
            >
              <AddIcon classes={{ root: classes.addIconCol }} />
            </IconButton>
          )}
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent classes={{ root: classes.extraContentArea }}>
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="flex-start"
            >
              {multiProduct ? (
                products.map((product, index) => {
                  return (
                    <ProductCardExtended
                      key={index}
                      product={product}
                      category={category}
                    ></ProductCardExtended>
                  )
                })
              ) : (
                <></>
              )}
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  )
}

export default ProductCard
