import React from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import CardActionArea from "@material-ui/core/CardActionArea"
import Grid from "@material-ui/core/Grid"
import { Link } from "gatsby"

import { makeStyles } from "@material-ui/core/styles"

import priceFormat from "../../helperFunc/priceFormat"

const useStyles = makeStyles(theme => ({
  boxGrids: {
    padding: "0.5rem",
  },
  mainCard: { borderRadius: "1rem" },
  titleArea: {
    backgroundColor: theme.palette.common.white,

    paddingTop: "0.4rem",
    paddingBottom: "0.4rem",
  },
  titleText: {
    fontSize: "0.75rem",
    lineHeight: "0.75rem",
    textAlign: "center",
  },

  priceText: {
    color: theme.palette.common.grey,
    textAlign: "center",
    fontSize: "0.7rem",
  },

  unavailable: {
    zIndex: "9",
    fontSize: "0.8rem",
    lineHeight: "0.9rem",
    width: "100%",
    textAlign: "center",
    backgroundColor: theme.palette.common.grey,
    padding: "0.5rem",
    color: theme.palette.common.white,
    textTransform: "uppercase",
  },
  imageArea: {
    height: "100%",
    width: "100%",
  },
}))

const ProductCardExtended = ({ product, category }) => {
  const classes = useStyles()

  let imgStyle =
    product.inStock === false
      ? { WebkitFilter: "grayscale(100%)", filter: "grayscale(100%)" }
      : {}

  return (
    <Grid item xs={6} classes={{ root: classes.boxGrids }}>
      <Card classes={{ root: classes.mainCard }} elevation={0}>
        <CardActionArea
          component={Link}
          to={`/${category.slug.toLowerCase()}/${encodeURIComponent(
            product.name.toLowerCase().replace(/\s/g, "")
          )}`}
        >
          <CardMedia
            component={Box}
            style={{
              backgroundImage: `url(${product.images[0].url})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              width: "100%",
              height: "100px",
              ...imgStyle,
            }}
          >
            {product.inStock === false ? (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                classes={{ root: classes.imageArea }}
              >
                <Grid item>
                  <Typography
                    variant="body1"
                    classes={{ root: classes.unavailable }}
                  >
                    {"Currently unavailable"}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </CardMedia>

          <CardContent classes={{ root: classes.titleArea }}>
            <Typography
              variant="body2"
              component="div"
              classes={{ root: classes.titleText }}
            >
              {product.name}
            </Typography>
            <Typography
              variant="body2"
              component="div"
              classes={{ root: classes.priceText }}
            >
              {product.price > 0
                ? priceFormat(product.price)
                : `${product.pricePrefix}`}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  )
}

export default ProductCardExtended
